<template>
  <b-card class="px-2 mb-3">
    <div
      class="mt-2 text-center"
    >

      <validation-observer
        #default="{invalid}"
      >
        <p>{{ $t('enter_mobile_for_verification') }}:</p>
        <b-form @submit.prevent="sendOtpCode()">
          <b-form-group
            :label="$t('mobile_number')"
            label-for="mobile-number"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('confirm_withdraw_code')"
              vid="mobile"
              rules="required"
            >
              <b-form-input
                id="mobile-number"
                v-model="mobileNumber"
                type="tel"
                placeholder="0916XXXXXXX"
                required
                class="w-md-50 w-lg-25 mx-auto"
              />
            </validation-provider>
          </b-form-group>
          <div
            class="mx-auto "
            style="width: 300px;"
          >
            <b-form-group class="mt-1 ">
              <vue-recaptcha
                ref="recaptcha"
                :sitekey="sitekey"
                :load-recaptcha-script="true"
                :theme="$store.state.appConfig.layout.skin == 'dark' ? 'dark' : ''"
                @expired="onCaptchaExpired"
                @verify="onVerify"
              />
            </b-form-group>
          </div>
          <b-button
            type="submit"
            variant="success"
            :disabled="!recaptcha || !mobileNumber || isVerified"
          >
            <div>
              <span v-if="!sendOtpLoading">{{ $t('send_code') }}</span>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </div>
          </b-button>

        </b-form>
      </validation-observer>
      <b-modal
        v-model="otpModal"
        :title="$t('confirm_withdraw')"
        centered
        size="md"
        hide-footer
        no-close-on-backdrop
      >
        <validation-observer
          #default="{invalid}"
        >
          <b-row>
            <b-col>
              <b-form-group>
                <div
                  v-if="mobileNumber"
                  class="my-1"
                >
                  {{ $t('enter_email_for_activation') }}
                </div>
                <div class="d-flex justify-content-between">
                  <label for="code">{{ $t('email') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  vid="email"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="email"
                      v-model="email"
                      name="email"
                      placeholder="example@gmail.com"
                      class="mb-1"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                v-if="mobileNumber"
                class="my-1"
              >
                {{ $t('enter_code_sent_to_mobile') }}
              </div>
              <b-form
                class="auth-login-form mb-2 "
              >

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="code">{{ $t('code') }}</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('code')"
                    vid="code"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="code"
                        v-model="confirmCode"
                        name="code"
                        placeholder="0000"
                        class="mb-1"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-button
                block
                size="md"
                variant="warning"
                :disabled="verifyCounter !== 0"
                @click="sendOtpCode(true)"
              >
                <div v-if="!resendOtpLoading">
                  <span>
                    {{ $t('resend') }}
                  </span>
                  <span v-if="verifyCounter !== 0">( {{ convertSecondsToMinutes(verifyCounter) }} )</span>
                </div>
                <b-spinner
                  v-else
                  small
                  size="10"
                  variant="light"
                  type="grow"
                />
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                block
                size="md"
                :disabled="invalid || confirmCode.length < 4"
                variant="success"
                @click="verify"
              >
                <span v-if="!verifyLoading">
                  {{ $t('widrawModal.confirm') }}
                </span>
                <b-spinner
                  v-else
                  small
                  size="10"
                  variant="light"
                  type="grow"
                />
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
    </div>
  </b-card>

</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axiosIns from '@/libs/axios'
import { convertSecondsToMinutes } from '@/utils'

export default {
  components: { VueRecaptcha },
  props: {
    redirectUrlName: {
      default: null,
      type: String,
    },
    questions: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      mobileNumber: this.$route.query.mobile || '',
      otpModal: false,
      otpNumber: '',
      email: this.$route.query.email || '',
      verifyCounter: 0,
      resendOtpLoading: false,
      sendOtpLoading: false,
      verifyLoading: false,
      confirmCode: '',
      recaptcha: '',
      isVerified: false,
      countdownInterval: null,
      convertSecondsToMinutes,

    }
  },
  computed: {
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.$router.push({ name: this.redirectUrlName })
    }
  },
  methods: {
    countDownTimer() {
      if (!this.countdownInterval) {
        this.countdownInterval = setInterval(() => {
          if (this.verifyCounter !== 0) {
            this.verifyCounter -= 1
          }
        }, 1000)
      }
    },
    sendOtpCode(resend = false) {
      if (resend) {
        this.resendOtpLoading = true
      } else {
        this.sendOtpLoading = true
      }
      axiosIns.post('campaign/send-otp', { uuid: this.$route.query.uuid, mobile: this.mobileNumber.replace(/^0/, ''), mobile_country_code: '98' }).then(res => {
        this.otpModal = true
        this.verifyCounter = res.data.results.expiration_time * 60
        this.countDownTimer()
        this.$swal({
          title: this.$t('code-sent'),
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).catch(error => {
        if (error.status === 428) {
          this.otpModal = true
          this.verifyCounter = error.data.results.time
          this.countDownTimer()
        }
      }).finally(() => {
        this.sendOtpLoading = false
        this.resendOtpLoading = false
      })
    },
    verify() {
      this.verifyLoading = true
      axiosIns.post('quick-register', {
        uuid: this.$route.query.uuid,
        mobile: this.mobileNumber.replace(/^0/, ''),
        questions: this.questions,
        email: this.email,
        code: this.confirmCode,
        recaptcha: this.recaptcha,
      }).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 6000,
          showConfirmButton: false,
        })

        // todo set token and login user then redirect to invest module deposit
        localStorage.setItem('token', res.data.results.access_token)
        localStorage.setItem('authenticated', true)
        if (this.redirectUrlName) {
          setTimeout(() => {
            this.$router.push({ name: this.redirectUrlName })
          }, 500)
        }
        this.otpModal = false
        this.isVerified = true
      }).catch(error => {
        console.log(error)
        this.$refs.recaptcha.reset()
        this.otpModal = false
      }).finally(() => {
        this.verifyLoading = false
      })
    },
    onVerify(response) {
      this.recaptcha = response
    },
    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.recaptcha = null
    },
  },
}
</script>
