<template>
  <div>
    <b-container class="text-center my-4">
      <b-card>
        <h2
          class=""
          style="font-weight: bold;"
        >
          {{ $t('active_email_and_mobile_to_countinu') }}
        </h2>
        <QuickRegister
          redirect-url-name="investModule-select-plan-type"
          questions="{}"
        />
      </b-card>
    </b-container>
  </div>
</template>

<script>
import QuickRegister from './components/quickRegister.vue'

export default {
  components: { QuickRegister },
  data() {
    return {

    }
  },
}
</script>
